import getWorkspace from "@/store/ai-workspace/actions/getWorkspace";
import updateWorkspace from "@/store/ai-workspace/actions/updateWorkspace";
import getWorkspaceSessions from "@/store/ai-workspace/actions/getWorkspaceSessions";
import getWorkspaces from "@/store/ai-workspace/actions/getWorkspaces";
import createWorkspace from "@/store/ai-workspace/actions/createWorkspace";

const actions = {
    getWorkspaces,
    getWorkspace,
    getWorkspaceSessions,
    updateWorkspace,
    createWorkspace,
}

export default actions;