const getters = {
    WORKSPACES_LIST: ({ workspacesList }) => workspacesList,
    WORKSPACE: ({ workspace }) => workspace,
    WORKSPACE_SESSIONS: ({ workspaceSessions }) => workspaceSessions,

    WORKSPACE_LIST_LOADING: ({ isWorkspacesListLoading }) => isWorkspacesListLoading,
    WORKSPACE_LOADING: ({ isWorkspaceLoading }) => isWorkspaceLoading,
    WORKSPACE_SESSIONS_LOADING: ({ isWorkspaceSessionsLoading }) => isWorkspaceSessionsLoading,
    SEND_MESSAGE_LOADING: ({ isSendMessageLoading }) => isSendMessageLoading,
    WORKSPACE_UPDATE_LOADING: ({ isWorkspaceUpdateLoading }) => isWorkspaceUpdateLoading,
}

export default getters;