const mutations = {
    SET_WORKSPACE: (state, workspace) => {
        state.workspace = workspace
    },
    SET_WORKSPACES_LIST: (state, workspacesList) => {
        state.workspacesList = workspacesList
    },
    SET_WORKSPACES_SESSIONS: (state, workspaceSessions) => {
        state.workspaceSessions = workspaceSessions
    },
    SET_WORKSPACE_LOADING: (state, payload) => {
        state.isWorkspaceLoading = payload;
    },
    SET_WORKSPACES_LIST_LOADING: (state, payload) => {
        state.isWorkspacesListLoading = payload;
    },
    SET_WORKSPACE_SESSIONS_LOADING: (state, payload) => {
        state.isWorkspaceSessionsLoading = payload;
    },
    SET_WORKSPACE_UPDATE_LOADING: (state, payload) => {
        state.isWorkspaceUpdateLoading = payload;
    }
}

export default mutations;